@charset "utf-8";
/*.WindowClientsContainer{

}*/
.WindowClientsContainer .WindowClientsHeadersContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSuperTitle,
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: bottom;
}
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSuperTitle{
    color: #153768;
}
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer{
    text-align: right;
}
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer button{
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #CCCCCC;
    color: #153768;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
    text-align: center;
}
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer button:hover{
    color: #ffffff;
    background-color: #153768;
}
.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSeparationLine{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
/*------------------------------------------*/
/*------------------------------------------*/
/*------------------------------------------*/
/*------------------------------------------*/
.WindowClientsContainer .WindowClientsList::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 1px transparent;
    background-color: transparent;
}

.WindowClientsContainer .WindowClientsList::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color:transparent;
}

.WindowClientsContainer .WindowClientsList::-webkit-scrollbar-thumb
{
    background-color: transparent;
}
.WindowClientsContainer .WindowClientsList{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    list-style: none;
    overflow: auto;
    white-space: nowrap;
    text-align: left;
}
.WindowClientsContainer .WindowClientsList .WindowClientsListItem{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
    border-right: 1px solid #CCCCCC;
}
.WindowClientsContainer .WindowClientsList .WindowClientsListItem:hover .WindowClientsListItem_ImageContainer,
.WindowClientsContainer .WindowClientsList .WindowClientsListItem:focus .WindowClientsListItem_ImageContainer{
    background-color: transparent;
}
.WindowClientsContainer .WindowClientsList .WindowClientsListItem:hover .WindowClientsListItem_TextContainer .WindowClientsListItem_Name,
.WindowClientsContainer .WindowClientsList .WindowClientsListItem:focus .WindowClientsListItem_TextContainer .WindowClientsListItem_Name{
    color: #00C7D6;
}
.WindowClientsContainer .WindowClientsList .WindowClientsListItem .WindowClientsListItem_Link{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    
}
.WindowClientsContainer .WindowClientsList .WindowClientsListItem .WindowClientsListItem_Image{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
@media screen and (min-width: 701px) {
    .WindowClientsContainer{
        margin: 6vh auto;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer{
        margin-bottom: 0 0 2vh 0;
    }
    /*.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSuperTitle,
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer{
       
    }*/
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSuperTitle{
        width: 70%;
        margin-right: 3%;
        font-size: 1.7em;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer{
        width: 25%;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer button{
        border-radius: 50%;
        padding: 5px 10px;
        font-size: 15px;
        margin: 0 5px;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSeparationLine{
        margin: 2vh 0 2vh 0;
    }
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    .WindowClientsContainer .WindowClientsList .WindowClientsListItem{
        margin: 1vh 60px 1vh 0;
        padding: 0vh 2vh;
        width: 200px;
    }
    .WindowClientsContainer .WindowClientsList .WindowClientsListItem .WindowClientsListItem_Link{
    }
    .WindowClientsContainer .WindowClientsList .WindowClientsListItem .WindowClientsListItem_Image{
    }
}
@media screen and (max-width: 700px) {
    .WindowClientsContainer{
        margin: 6vh auto;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer{
        margin-bottom: 0 0 2vh 0;
    }
    /*.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSuperTitle,
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer{
       
    }*/
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSuperTitle{
        width: 70%;
        margin-right: 3%;
        font-size: 1.7em;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer{
        width: 25%;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer button{
        border-radius: 50%;
        padding: 5px 10px;
        font-size: 15px;
        margin: 0 5px;
    }
    .WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsSeparationLine{
        margin: 2vh 0 4vh 0;
    }
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    /*------------------------------------------*/
    .WindowClientsContainer .WindowClientsList .WindowClientsListItem{
        margin: 1vh 60px 1vh 0;
        width: 250px;
    }
    .WindowClientsContainer .WindowClientsList .WindowClientsListItem .WindowClientsListItem_Link{
    }
    .WindowClientsContainer .WindowClientsList .WindowClientsListItem .WindowClientsListItem_Image{
    }
}

